<template>
	<div class="medical-records">
		<div class="patient-info">
			<div class="info-details">
				<div class="patient-detail">
					<p class="detail-name">
						<span class="">{{$t("patient.name")}}：{{infoData.name}}</span>
						<span class="gender" ></span>
						<span class="tag">{{infoData.age}}{{$t("patient.annum")}}</span>
					</p>
					<p class="detail-Idcard">
						<span class="lable">{{$t("patient.IDCard")}}（{{infoData.idType?IdColumns.find(item => infoData.idType == item.value).text:''}}）：</span>
						<span class="val">
							{{infoData.idCode}}
						</span>
					</p>
					<p class="detail-Idcard">
						<span class="lable">{{$t("patient.emergencyTel")}}：</span>
						<span class="val">
							{{infoData.emergencyTel||'无'}}
						</span>
					</p>
				</div>
				<div class="detail-btn">
					<span class="btn" @click="gopath">{{$t("patient.editinfobtn")}}</span>
					<span class="btn" @click="deletePatient">{{$t("patient.unbund")}}</span>
					<!-- <span class="btn" @click="seeImsge">{{$t("patient.ImageData")}}</span> -->
				</div>
			</div>
			<div class="hosp-box flex">
				<div class="box-btn"  @click="Indevelop">
					<img src="@/assets/img/make-hosp-icon.png" alt="">
					<p class="subscribe-box" :class="{'hand':subscribeType}">
						{{$t("patient.makehosp")}} 
						<!-- <span v-if="subscribeType">处理中</span> -->
					</p>
				</div>

				<div class="box-btn" v-if="!hospType" @click="handleHosp">
					<img src="@/assets/img/hosp-btn-icon.png" alt="">
					<span>{{$t("patient.handleHosp")}}</span>
				</div>
				<div class="box-btn btnhandle" v-if="hospType" @click="handleGoList">
					<img src="@/assets/img/inhandle-hosp-icon.png" alt="">
					<span>{{$t("patient.inhandle")}}</span>
				</div>
			</div>
		</div>
		<div class="visitRecord">
			<p class="title">
				<span>{{$t("patient.visitRecord")}}</span>
				<span class="more-btn" @click="seeRecord" v-if="visitRecordList.length>1">
					{{$t("patient.more")}}
				</span>
			</p>
			<div class="list-container">
				<div class="container-item" v-for="(item, index) in visitRecordList" :key="index" v-if="index < 1">
					<div class="clinic">
						<div class="clinic-head" :class="{'hosp-head':item.type == 137002}">
							<i class="icon"></i>
							<span class="txt">{{item.type == 137002? $t("patient.hospitalization"):$t("patient.clinic")}}</span>
						</div>
						<div class="clinic-info">
							<div class="info-txt">
								<p class="txt" v-if="item.type == 137001">{{$t("patient.clinicTime",{time:getLocalMinutesTime(item.cdt)})}}</p>
								<p class="txt" v-if="item.type == 137002">{{$t("patient.hosptime")}}：{{getLocalMinutesTime(item.inHospitalDt)}}</p>
								<p class="txt" v-if="item.type == 137002">{{$t("patient.leavehosp")}}：{{item.outHospitalDt?getLocalMinutesTime(item.outHospitalDt):$t("patient.inhosp")}}</p>
								<p class="txt">{{$t("patient.patientNum",{num:item.caseCount})}}</p>
							</div>
							<div class="info-btn" @click="goDetail(item)">
								{{$t("patient.seeDetails")}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="list-empty" v-if="visitRecordList.length == 0">
				<img src="@/assets/img/visitrecord-emtry.png" alt="">
			</div>
		</div>
		<image-data :patientId="patientId"></image-data>
	</div>
</template>

<script>
import { patientDelete } from "@/api/patient";
import { getPatientInfo, medicalQuery, hospAdd,  hospRecord} from "@/api/patient"
import { getLocalMinutesTime } from "@/utils/date";
import imageData from "./imageData.vue"
import { IdColumns } from "@/utils/staticData"
import { Inhospitalquery } from "@/api/index";
export default {
	components:{imageData},
	data(){
		return{
			infoData:{},
			visitRecordList:[],
			hospType:false,
			subscribeType:false,
			patientId:this.$route.query.patientId,
			getLocalMinutesTime,
			IdColumns,
			yuyueZhuyuanId:""
		}
	},
	created(){
		document.title = this.$t("patient.patientDetails")
		this.getPatientData();
		this.getPatientQuery();
		this.getsubscribe();
		this.getHospRecord();
	},
	methods:{
		deletePatient(){
			let that = this;
			this.$dialog.confirm({
				title: that.$t("patient.confirmtitle"),
				message: that.$t("patient.confirmMessage",{name:that.infoData.name}),
				confirmButtonText:that.$t("patient.confirmButtonText"),
				confirmButtonColor:"#F56C6C",
				className:"unbindconfrim"
			})
			.then(() => {
				const toast = this.$Toast.loading({
					duration: 0, // 持续展示 toast
					forbidClick: true,
					message: that.$t("message.loadingTxt")
				});
				patientDelete({id:that.$route.query.patientId}).then(res=>{
					// toast.close()
					 if(res.data.errorCode == 0){
						that.$Toast(that.$t("comonTxt.operationsuccess"))
						that.$router.go(-1)
					}else{
						that.$Toast(that.$t("comonTxt.operationfail"))
					}
				})
				// on confirm
			}).catch(()=>{

			})
        },
		seeRecord(){
			this.$router.push({
				path:"/patient/patientRecord",
				query:{
					patientId:this.patientId
				}
			})
		},
		seeImsge(){
			this.$router.push({
				path:"/patient/imageData",
				query:{
					patientId:this.patientId
				}
			})
		},
		// 查询住院记录
		getHospRecord(){
			hospRecord({
				patientId:this.$route.query.patientId
			}).then(res=>{
				if(res.data.data&&res.data.data.length!=0){
					this.hospType = true;
				}
			})
		},
		getsubscribe(){
			Inhospitalquery({
				patientId:this.$route.query.patientId
			}).then(res => {
				if(res.data.data&&res.data.data.length!=0){
					this.subscribeType = true;
					this.yuyueZhuyuanId = res.data.data[0].yuyueZhuyuanId
				}
			})
		},
		handleHosp(){
			const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
			hospAdd({
				patientId:this.patientId
			}).then(res=>{
				toast.clear();
				if(res.data.errorCode == 0){
					this.hospType = true;
				}else{
					this.$toast(res.data.errorMsg)
				}
			})
		},
		handleGoList(){
			this.$router.push({
				path:"/admission/index"
			})
		},
		Indevelop(){
			this.$router.push({
				path:"/subscribeHospital/index",
			})
		},
		// 查看就诊详情
		goDetail(item){
			this.$router.push({
				path:"/patient/medicalRecordsDetails",
				query:{
					medicalId:item.id
				},
			})
		},
		// 获取就诊记录
		async getPatientQuery(){
			let result = await medicalQuery({
				patientId:this.$route.query.patientId,
				pageSize:10000,
				pageNum:1,
			})
			if(result.data.errorCode == 0){
				this.visitRecordList = result.data.data.data;
			}
		},
		getPatientData(){
			const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            getPatientInfo({id:this.$route.query.patientId}).then(res=>{
                toast.close();
                this.infoData = res.data.data.user;
            })
		},
		gopath(){
			this.$router.push({
                path:"/patient/edit",
                query:{
                    patientId:this.$route.query.patientId
                }
            })
		}
	}
}
</script>

<style lang="scss" >

.medical-records{
  	min-height: 100%;
	background: #F5F6F9;
	height: max-content;
	overflow: auto;
	box-sizing: border-box;
	padding:  0 0.6rem;
	p{
		margin: initial;
	}
	.patient-info{
		border-radius: 0.4rem;
		background: linear-gradient(0deg, #569CFF 0%, #DBEEFC 100%);
		box-shadow: 0 0.12rem 0.3rem 0 rgba(203, 219, 235, 0.25);
		border-radius: 0.4rem;
		padding: 0.4rem;
		margin: 0.4rem 0;
		.info-details{
			padding: 0.6rem;
			border-radius: 0.32rem;
			background: #fff;
			.patient-detail{
				margin-bottom: 0.3rem;
				.detail-name{
					margin-bottom: 0.3rem;
					display: flex;
					align-items: center;
					font-size: 0.64rem;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
					.gender{
						width: 0.7rem;
						height: 0.7rem;
						margin: 0 0.3rem;
						background: url("~@/assets/img/patient-gender-man.png") no-repeat;
						background-size: 100% 100%;
					}
					.tag{
						font-size: 0.52rem;
						font-family: PingFang SC;
						font-weight: 400;
						color: #666666;
						padding: 0.1rem 0.3rem;
						border: 0.02rem solid #666666;
						border-radius: 0.4rem;
					}
				}
				.detail-Idcard{
					margin-bottom: 0.3rem;
					font-size: 0.52rem;
					font-family: PingFang SC;
					font-weight: 400;
					color: #666666;
					display: flex;
					align-items: flex-start;
					.lable{
						flex: 0 0 auto
					}
					.val{
						flex: 1;
						word-break:break-all
					}
				}
			}
			.detail-btn{
				font-size: 0.52rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #49A7FF;
				.btn{
					margin-right: 1.2rem;
				}
			}
		}
		.hosp-box{
			margin: 0.52rem 0;
			.box-btn{
				flex: 1;
				height: 1.72rem;
				width: 304px;
				border: 0.04rem solid #FFFFFF;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0.32rem;
				font-size: 0.64rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				&:last-child{
					margin-left: 0.6rem;
				}
				img{
					height: 0.54rem;
					width: auto;
					margin-right: 0.2rem;
				}
				.subscribe-box{
					position: relative;
					
				}
				.hand{
					&::after{
						// content: ' ';
						// top: -0.31rem;
						// right: -0.4rem;
						// position: absolute;
						// display: block;
						// width: 0;
						// height: 0;
						// border-color: transparent;
						// border-style: solid;
						// border-width: 0.3rem;
						// border-left-width: 0;
						// border-right-color: #FFC00F;
						// transform: rotate(180deg);
					}
					span{
						position: absolute;
						font-size: 0.4rem;
						border-radius: 0.15rem;
						width: max-content;
						padding: 0.08rem 0.1rem;
						background: #FFC00F;
						top: -0.6rem;
						right: -1.51rem;
						z-index: 99;
					}
				}
				
			}
			.btnhandle{
				background: #D1E8FC;
				color: #49A7FF;
			}
		}
	}
	.visitRecord{
		margin-top: 1rem;
		.title{
			font-size: 0.72rem;
			font-family: PingFang SC;
			font-weight: 600;
			color: #333333;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			span{
				position: relative;
				z-index: 11;
			}
			.more-btn{
				font-size: 0.48rem;
				font-family: PingFang SC;
				font-weight: 400;
				color: #999999;
			}
			&::after{
				content: " ";
				position: absolute;
				z-index: 1;
				left: 0;
				bottom: 0;
				width: 2rem;
				height: 0.44rem;
				background: linear-gradient(90deg, #90D3FF 0%, #F5F6FA 100%);
			}
		}
		.list-container{
			margin-top: 0.3rem;
			.container-item{
				border-radius: 0.32rem;
				overflow: hidden;
				background: #FFFFFF;
				margin-bottom: 0.6rem;
				.clinic{
					.clinic-head{
						height: 1.8rem;
						padding: 0 0.6rem;
						display: flex;
						align-items: center;
						background: linear-gradient(270deg, #F1FCFF 0%, #E7FEFF 100%);
						.icon{
							width: 0.76rem;
							height: 0.76rem;
							margin-right: 0.3rem;
							background: url("~@/assets/img/clinic-icon.png");
							background-size: 100% 100%;
						}
						.txt{
							font-size: 0.64rem;
							font-family: PingFang SC;
							font-weight: 600;
							color: #333333;
						}
					}
					.hosp-head{
						background: linear-gradient(270deg, #EFF8FF 0%, #CDE9FF 100%);
						.icon{
							background-image: url("~@/assets/img/hosp-head-icon.png");
						}
					}
					.clinic-info{
						display: flex;
						padding: 0.6rem;
						box-sizing: border-box;
						align-items: center;
						.info-txt{
							flex: 1;
							.txt{
								font-size: 0.56rem;
								font-family: PingFang SC;
								font-weight: 400;
								color: #666666;
								margin-bottom: 0.44rem;
								&:last-child{
									margin: 0;
								}
							}
						}
						.info-btn{
							flex: 0 0 auto;
							margin-left: 0.4rem;
							font-size: 0.56rem;
							font-family: PingFang SC;
							font-weight: 400;
							color: #FFFFFF;
							background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
							padding: 0.24rem 0.6rem;
							border-radius: 0.6rem;
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.list-empty{
	height: 6rem;
	// background: #fff;
	margin-top: 0.6rem;
	border-radius: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	img{
		height: 4rem;
	}
}
 .unbindconfrim{
	 width: 70%;
	 .van-dialog__message {
		font-size: 0.64rem;
		color: #333333;
		height: auto;
		line-height: unset;
		padding-top: 0.4rem;
	}
	.van-dialog__header{
		font-weight: 600;
		font-size: 0.7rem;
	}
	.van-dialog__cancel, .van-dialog__confirm{
		height: 1.8rem;
	}
	.van-button__text{
		font-size: 0.7rem;
	}
}
</style>